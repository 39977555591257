<!-- 选择投资机构 -->
<template>
  <a-select
    show-search
    v-model="name"
    style="width: 200px"
    @select="onSelect"
    @search="onSearch"
    @change="onChange"
    :showArrow="false"
    placeholder="请选择已有投资机构"
    @popupScroll="popupScroll"
  >
    <a-select-option
      v-for="(org, index) in orgSource"
      :key="org.id"
      :value="org.name"
      :test-attr="index"
      >{{ org.name }}</a-select-option
    >
  </a-select>
</template>

<script>
import debounce from "lodash/debounce";
import { searchOrg } from "@/api/organization";
export default {
  name: "InvestOrgSelect",
  props: {
    // 是否数据库已包含该投资机构
    include: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      name: this.value,
      orgSource: [],
      page: 0,
      searchWord: "",
      allLoaded: false,
    };
  },
  created() {
    this.onSearch = debounce(this.onSearch, 800);
  },
  methods: {
    popupScroll(e) {
      if (this.allLoaded) {
        return;
      }
      const { target } = e;
      if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
        this.fetchData(this.searchWord);
      }
    },
    onSelect(value) {
      this.$emit("input", value);
      this.$emit("update:include", true);
    },
    onSearch(keyword) {
      if (keyword.length < 1) return;
      this.page = -1;
      this.searchWord = keyword;
      this.orgSource = [];
      this.allLoaded = false;
      this.fetchData(keyword);
      this.$emit("search", keyword);
    },
    fetchData(keyword) {
      searchOrg({ keyword: keyword, page: this.page + 1 }).then((res) => {
        this.page += 1;
        this.orgSource = this.orgSource.concat(res.data.content);
        this.allLoaded = res.data.content.length < 10;
        this.$emit("update:include", false);
      });
    },
    // 触发model的表单校验
    onChange(name) {
      this.$emit("change", name);
    },
  },
};
</script>