<template>
  <a-form :form="form">
    <a-input-group compact v-if="editable">
      <invest-org-select style="width: 80%" @change="handleChange" />
      <a-button icon="plus" style="width: 20%" @click="handleAdd" />
    </a-input-group>
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :row-key="(record) => record.name"
      :pagination="{ pageSize: 10 }"
      style="margin-top: 8px"
    >
      <span slot="name" slot-scope="text, record, index">
        <a-form-item style="margin-bottom: 0">
          <a-input
            disabled
            style="
              border: none;
              color: rgba(0, 0, 0, 0.65);
              background-color: white;
            "
            v-decorator="[
              `organizations[${index}].name`,
              { initialValue: text },
            ]"
          />
        </a-form-item>
      </span>
      <span slot="type" slot-scope="text, record, index">
        <a-form-item style="margin-bottom: 0">
          <a-select
            :disabled="!editable"
            v-decorator="[
              `organizations[${index}].type`,
              { initialValue: text },
            ]"
          >
            <a-select-option value="LEAD">领投</a-select-option>
            <a-select-option value="FOLLOW">跟投</a-select-option>
            <a-select-option value="COOPERATE">合投</a-select-option>
            <a-select-option value="SINGLE">独投</a-select-option>
            <a-select-option value="UNKNOWN">未知</a-select-option>
          </a-select>
        </a-form-item>
      </span>
      <span slot="investmentAmount" slot-scope="text, record, index">
        <a-form-item style="margin-bottom: 0">
          <a-input-number
            :disabled="!editable"
            :min="0"
            :max="100000000"
            :style="{ width: '8em' }"
            v-decorator="[
              `organizations[${index}].investmentAmount`,
              { initialValue: text },
            ]"
          />
        </a-form-item>
      </span>
      <span slot="moneyType" slot-scope="text, record, index">
        <a-form-item style="margin-bottom: 0">
          <a-select
            :disabled="!editable"
            showArrow
            :maxTagTextLength="5"
            placeholder="请选择"
            v-decorator="[
              `organizations[${index}].moneyType`,
              { initialValue: text },
            ]"
          >
            <a-select-option
              v-for="item in moneyType"
              :value="item"
              :key="item.toString(36)"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="handleDelete(record)">删除</a>
      </span>
    </a-table>
  </a-form>
</template>

<script>
import InvestOrgSelect from "@/components/Selectors/InvestOrgSelect";
import { getFinanceInfo, updateFinance } from "@/api/company";
import moment from "moment";

const columns = [
  {
    title: "机构名称",
    dataIndex: "name",
    scopedSlots: {
      customRender: "name",
    },
  },
  {
    title: "投资类型",
    dataIndex: "type",
    scopedSlots: {
      customRender: "type",
    },
  },
  {
    title: "投资金额（万元）",
    dataIndex: "investmentAmount",
    scopedSlots: {
      customRender: "investmentAmount",
    },
  },
  {
    title: "类型",
    dataIndex: "moneyType",
    width: "8em",
    scopedSlots: {
      customRender: "moneyType",
    },
  },
];
export default {
  components: {
    InvestOrgSelect,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
      loading: false,
      data: [],
      columns,
      moneyType: ["美元", "港元", "英镑", "欧元", "日元", "人民币", "其他"],
      searchData: [],
      selectedValue: undefined,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.columns = this.columns.slice(0, 4);
      if (this.editable && this.columns.length < 5) {
        this.columns.push({
          title: "操作",
          scopedSlots: {
            customRender: "action",
          },
        });
      }
      getFinanceInfo({
        id: this.record.id,
      }).then((res) => {
        let orgs = res.data.organizations;
        orgs = orgs.map((item) => {
          return {
            ...item,
            investmentAmount: item.investmentAmount
              ? item.investmentAmount.replace(/[^\d.]/g, "")
              : null,
            moneyType: item.investmentAmount
              ? item.investmentAmount.replace(/[^\u4E00-\u9FA5]/g, "")
              : null,
          };
        });
        this.data = orgs;
        this.loading = false;
      });
    },
    handleAdd() {
      if (
        this.selectedValue.length > 0 &&
        this.data.filter((item) => item.name === this.selectedValue).length < 1
      ) {
        this.data.push({
          name: this.selectedValue,
          type: "",
        });
      }
    },
    handleDelete(record) {
      let index = this.data.indexOf(record);
      if (index !== -1) {
        this.data.splice(index, 1);
      }
    },
    handleChange(value) {
      this.selectedValue = value;
    },
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!this.editable) {
            return resolve(true);
          }
          if (!err) {
            let organizations = values.organizations
              ? values.organizations.map((item) => {
                  return {
                    name: item.name,
                    type: item.type == "" ? null : item.type,
                    investmentAmount: item.investmentAmount + item.moneyType,
                  };
                })
              : [];
            let finance = this.record;
            let formData = {
              id: finance.id,
              date: moment(finance.date).utc(480),
              financeAdvisor: finance.financeAdvisor,
              organizations: organizations,
              moneyNum: finance.moneyNum,
              realTime: moment(finance.realTime).utc(480),
              round: finance.round,
            };
            updateFinance(formData).then(() => {
              this.$message.success("更新成功");
              resolve(true);
            });
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.company-list {
  background-color: white;
  padding: 20px;
  .input-container {
    float: right;
  }
}
</style>
