<template>
  <a-form :form="form">
    <a-form-item label="轮次" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入轮次"
        v-decorator="['round', { initialValue: record.round }]"
        :disabled="!editable"
      />
    </a-form-item>
    <a-form-item
      label="金额（万元）"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-input
        v-decorator="['moneyNum', { initialValue: record.moneyNum }]"
        type="number"
        placeholder="请输入金额"
        :disabled="!editable"
      />
    </a-form-item>
    <a-form-item label="公开日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-date-picker
        v-decorator="['date', { initialValue: record.date?moment(record.date):null }]"
        placeholder="请输入公开日期"
        :disabled="!editable"
      />
    </a-form-item>
    <a-form-item
      label="实际投资时间"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-date-picker
        v-decorator="['realTime', { initialValue: record.realTime?moment(record.realTime):null }]"
        placeholder="请输入实际投资时间"
        :disabled="!editable"
      />
    </a-form-item>
    <a-form-item label="投资顾问" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="[
          'financeAdvisor',
          { initialValue: record.financeAdvisor },
        ]"
        placeholder="请输入投资顾问"
        :disabled="!editable"
      />
    </a-form-item>
    <a-form-item label="投资概况" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['investorName', { initialValue: record.investorName }]"
        placeholder="请输入投资概况"
        title="可填写包含知名投资机构、其他投资机构、个人投资者在内的全部投资者信息或公开发行、未披露等其他类型信息。"
        :disabled="!editable"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { addFinance, updateFinance, getFinanceInfo } from "@/api/company";
import moment from "moment";

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true
    },
    groupId:{
      type: Number
    }
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
    };
  },
  methods: {
    moment,
    onOk() {
      return new Promise((resolve) => {
        if(!this.editable) {
         return resolve(true);
      }
        this.form.validateFields((err, values) => {
          if (!err) {
            if (this.record.id) {
              // 更新
              getFinanceInfo(this.record).then((res) => {
                let organizations = res.data.organizations;
                let finance = values;
                let formData = {
                  id: this.record.id,
                  date: finance.date
                    ? moment(finance.date).utc(480)
                    : finance.date,
                  financeAdvisor: finance.financeAdvisor,
                  organizations: organizations,
                  moneyNum: finance.moneyNum,
                  realTime: finance.realTime
                    ? moment(finance.realTime).utc(480)
                    : finance.realTime,
                  round: finance.round,
                  investorName: finance.investorName,
                  groupId:this.groupId
                };
                updateFinance(formData).then(() => {
                  this.$message.success("更新成功");
                  resolve(true);
                });
              });
            } else {
              // 创建
              let finance = values;
              let formData = {
                companyId: this.record.companyId,
                date: finance.date
                  ? moment(finance.date).utc(480)
                  : finance.date,
                financeAdvisor: finance.financeAdvisor,
                organizations: [],
                moneyNum: finance.moneyNum,
                realTime: finance.realTime
                  ? moment(finance.realTime).utc(480)
                  : finance.realTime,
                round: finance.round,
                investorName: finance.investorName,
                groupId:this.groupId
              };
              addFinance(formData).then(() => {
                this.$message.success("添加成功！");
                resolve(true);
              });
            }
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
