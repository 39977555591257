<template>
  <div id="App">
    <a-card title="企名片融资数据" style="margin-bottom: 20px">
      <a-button
        type="primary"
        slot="extra"
        @click="handleUpdate"
        v-if="hasAction('update-external-data')"
      >
        更新融资数据
      </a-button>
      <transition name="fold-content">
        <div v-show="selectedRowKeys.length > 0" class="multiple-box">
          <span class="tip"
            >已选择<span class="num">{{ selectedRowKeys.length }}</span
            >项</span
          >
          <a-divider type="vertical" />
          <span class="btn" @click="cancelSelect">取消选择</span>
          <a-divider type="vertical" v-if="hasAction('edit-company-info')" />
          <span
            class="btn"
            @click="handleCopy"
            v-if="hasAction('edit-company-info')"
            >复制到分享起源</span
          >
        </div>
      </transition>
      <a-table
        :columns="qmpColumns"
        :dataSource="qmpData"
        :row-selection="rowSelection"
        :loading="loading"
        :row-key="(record) => record.id"
        :pagination="{ pageSize: 10 }"
      >
        <template slot="qmpDataManage" slot-scope="text, record">
          <div>
            <a @click="handleEditOrg(record, false)">查看投资机构</a>
            <a-divider type="vertical" />
            <a @click="handleEdit(record, false)">查看详情</a>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-card v-for="item in data" :key="item.id" style="margin-bottom: 20px">
      <span slot="title">{{ item.orgName }}-{{ item.groupName }}融资数据</span>
      <div class="action-area">
        <a-button
          v-if="hasAction('edit-company-info')"
          type="primary"
          icon="plus"
          @click="handleAdd(item.groupId)"
        >
          新增融资信息
        </a-button>
      </div>
      <a-table
        :columns="columns"
        :dataSource="item.finance"
        :loading="loading"
        rowKey="id"
        :pagination="{ pageSize: 10 }"
      >
        <template slot="dataManage" slot-scope="text, record">
          <div>
            <a @click="handleEditOrg(record)">修改投资机构</a>
            <a-divider type="vertical" />
            <a @click="handleEdit(record, true, item.groupId)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确认移除该条信息吗？"
              ok-text="是"
              cancel-text="否"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import {
  getFinanceList,
  deleteFinance,
  updateFinanceFromQMP,
  copyFinance,
} from "@/api/company";
import FinanceForm from "./components/FinanceForm";
import OrganizationForm from "./components/OrganizationForm";
import CopyForm from "./components/CopyForm.vue";

const qmpColumns = [
  {
    title: "轮次",
    dataIndex: "round",
  },
  {
    title: "金额（万元）",
    dataIndex: "moneyNum",
  },
  {
    title: "公开日期",
    dataIndex: "date",
  },
  {
    title: "投资机构",
    dataIndex: "orgs",
    customRender: (orgs) => {
      return orgs&&orgs.map((item) => item.name).join("、");
    },
  },
  {
    title: "管理",
    dataIndex: "finance-manage",
    scopedSlots: {
      customRender: "qmpDataManage",
    },
  },
];

const columns = [
  {
    title: "轮次",
    dataIndex: "round",
  },
  {
    title: "金额（万元）",
    dataIndex: "moneyNum",
  },
  {
    title: "公开日期",
    dataIndex: "date",
  },
  {
    title: "投资机构",
    dataIndex: "orgs",
    customRender: (orgs) => {
      return orgs&&orgs.map((item) => item.name).join("、");
    },
  },
];

export default {
  data() {
    return {
      loading: false,
      data: [],
      qmpData: [],
      groups: [],
      selectedRows: [],
      selectedRowKeys: [],
      columns,
      qmpColumns,
      finance: {
        dataSource: [],
        info: {
          round: "",
          investorName: "",
          moneyNum: "",
          date: "",
          realTime: "",
          financeAdvisor: "",
        },
      },
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  created() {
    if (this.hasAction("edit-company-info") && this.columns.length < 5) {
      this.columns.push({
        title: "管理",
        dataIndex: "finance-manage",
        scopedSlots: {
          customRender: "dataManage",
        },
      });
    }
    this.companyId = this.$route.params.companyId;
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.data = [];
      this.qmpData = [];
      this.selectedRowKeys = [];
      this.selectedRows = [];
      getFinanceList({
        companyId: this.companyId,
      }).then((res) => {
        res.data.forEach((item) => {
          if (item.groupName === "QMP") {
            item.finance.forEach((i) => {
              this.qmpData.push(i);
            });
          } else {
            this.data.push(item);
          }
        });
        this.data.forEach((item) => {
          this.groups.push({
            id: item.groupId,
            name: item.orgName + "-" + item.groupName,
          });
        });
        this.loading = false;
      });
    },
    handleAdd(groupId) {
      this.$dialog(
        FinanceForm,
        {
          record: { companyId: this.companyId },
          groupId,
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "新增",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleEdit(record, editable = true, groupId) {
      this.$dialog(
        FinanceForm,
        {
          record,
          editable,
          groupId,
          on: {
            ok: () => {
              if (editable) {
                this.loadData();
              }
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "修改",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleEditOrg(record, editable = true) {
      this.$dialog(
        OrganizationForm,
        {
          record,
          editable,
          on: {
            ok: () => {
              if (editable) {
                this.loadData();
              }
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "修改",
          width: 800,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleDelete(record) {
      deleteFinance(record).then(() => {
        this.loadData();
        this.$message.success("删除成功！");
      });
    },
    handleUpdate() {
      this.$dialog(
        {
          render() {
            return (
              <div>
                此更新功能需要调用企名片融资数据接口，会消耗一定资源且覆盖原有信息资料，请认真核查后再决定是否更新公司融资数据。
              </div>
            );
          },
        },
        {
          on: {
            ok: () => {
              updateFinanceFromQMP({ companyId: this.companyId }).then(
                (res) => {
                  if (res.code === 0) {
                    this.$message.success("更新企名片数据成功！");
                    this.loadData();
                  }
                }
              );
            },
          },
        },
        {
          title: "提示",
          okText: "确定更新",
          width: 400,
          height: 200,
        }
      );
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    removeSelecedtKey(key) {
      let index = this.selectedRowKeys.findIndex((item) => item === key);
      this.selectedRowKeys.splice(index, 1);
      this.selectedRows.splice(index, 1);
    },
    cancelSelect() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    handleCopy() {
      let _this = this;
      this.$dialog(
        CopyForm,
        {
          groups: this.groups,
          on: {
            copy: (ids) => {
              _this.copy(ids);
            },
          },
        },
        {
          title: "复制到分享起源",
          width: 450,
          centered: true,
          maskClosable: false,
        }
      );
    },
    copy(ids = []) {
      if (ids.length == 0) return;
      let copyList = [];
      this.selectedRows.forEach((item) => {
        copyList.push(item.id);
      });
      let formData = [];
      ids.forEach((id) => {
        formData.push({
          groupId: id,
          ids: copyList,
        });
      });
      copyFinance(formData).then(() => {
        this.loadData();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.action-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.multiple-box {
  height: 40px;
  background: #1890ff;
  line-height: 40px;
  color: #fff;
  padding: 0 20px;
  .tip {
    margin-right: 10px;
    color: #e8e8e8;
  }
  .num {
    color: #fff;
    font-weight: bold;
    margin: 0 3px;
  }
  .btn {
    margin: 0 10px;
    cursor: pointer;
  }
  .ant-divider {
    background: #a9a9a9;
  }
}
</style>
